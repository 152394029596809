.jobContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 70%;
  min-width: 70%;
  margin: 0 auto;
  font-family: 'Source Sans Pro';
  animation: apparition 500ms ease-in-out;

  @media (max-width: 1199px) {
    max-width: 80%;
    min-width: 80%;
  }
}

@keyframes apparition {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.img {
  max-width: 200px;
  align-self: flex-start;
  transform: translate(-60px, 0);

  @media (max-width: 1199px) {
    max-width: 100px;
    transform: translate(-30px, 0);
  }
}

.h3 {
  font-size: 26px;
  font-weight: 400;
  font-family: 'Source Sans Pro';
  margin-top: 0px;
  align-self: flex-start;

  @media (max-width: 1199px) {
    font-size: 18px;
  }
}
