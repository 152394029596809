.activityContent {
  width: 100%;
  margin-bottom: 5%;

  @media (max-width: 1199px) {
    margin-bottom: 10%;
  }
}

.img {
  max-width: 200px;
  align-self: flex-start;
}

.h3 {
  font-size: 26px;
  font-weight: 400;
  font-family: 'Source Sans Pro';
  margin-top: 0px;
  align-self: flex-start;
}

.jobHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 1199px) {
    flex-direction: column;
    gap: 5px;
    align-items: center;
  }
}

.h4 {
  margin: 0px;
  font-family: 'Source Sans Pro';
  font-size: 28px;
  font-weight: 400;
  align-self: flex-start;
  position: relative;

  @media (max-width: 1199px) {
    font-size: 20px;
  }
}

.line {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #000;
}

.technoIcons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  align-self: flex-end;

  @media (max-width: 1199px) {
    align-self: center;
    margin: 5px auto;
  }
}

.h5 {
  font-family: 'Source Sans Pro';
  font-size: 26px;
  font-weight: 200;
  align-self: start;
  margin: 0px;

  @media (max-width: 1199px) {
    font-size: 18px;
  }
}

.jobDescription {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-family: 'Source Sans Pro';
  font-size: 22px;
  font-weight: 300;
  margin: 5px;

  @media (max-width: 1199px) {
    font-size: 16px;
    flex-direction: column;
    gap: 5px;
    align-items: center;
  }
}

.ul {
  font-size: 22px;
  font-weight: 300;
  align-self: flex-start;
  margin: 0px;
  text-align: justify;

  @media (max-width: 1199px) {
    font-size: 16px;
    margin: 5px auto;
    padding-left: 20px;
  }
}
