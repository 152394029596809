@import url('../../variables.scss');

.footer {
  flex-shrink: 0;
  min-height: 100%;
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  margin-top: 20px;

  & > div {
    margin: auto 20px;
    font-family: 'Source Sans Pro';

    @media (max-width: 1199px) {
      font-size: 15px;
    }
  }
}
