@import url('../../variables.scss');

.icon {
  margin-right: 5px;
}

.img {
  @media (max-width: 1199px) {
    min-width: 16px;
    max-width: 16px;
    min-height: 16px;
    max-height: 16px;
  }

  @media (min-width: 1200px) {
    min-width: 24px;
    max-width: 24px;
    min-height: 24px;
    max-height: 24px;
  }
}
