.container {
  background-color: #dddddd;
  background-image: url('../../assets/newBackGround.svg');
  background-size: cover;
  background-position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: relative;
}

.h1 {
  font-size: 60px;
  font-weight: 300;
  font-family: 'Source Sans Pro';
  margin: 0px;

  @media (max-width: 1199px) {
    font-size: 40px;
  }
}

.h2 {
  font-size: 75px;
  font-weight: 700;
  font-family: 'Source Sans Pro';
  margin: 0px;

  @media (max-width: 1199px) {
    font-size: 50px;
  }
}

.button {
  color: white;
  font-size: 70px;
  font-weight: 300;
  font-family: 'Source Sans Pro';
  background-color: #459c5f;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 28px;
  font-weight: 600;
  font-family: 'Source Sans Pro';
  margin-top: 40px;
  cursor: pointer;

  @media (max-width: 1199px) {
    font-size: 20px;
  }
}

.title {
  transform: translate(0, 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: apparition 1s ease-in-out;
}

.footerLine {
  animation: apparition 1s ease-in-out;
  transform: translate(0, 150px);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    margin: 50px;
  }

  @media (max-width: 1199px) {
    width: 80%;
    & > div {
      margin: auto;
    }
  }
}

.socials {
  position: absolute;
  bottom: 5%;
  left: 5%;
}

.menu {
  position: absolute;
  bottom: 5%;
  right: 5%;
}

@keyframes apparition {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
