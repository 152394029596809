.menu {
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 400;
  font-family: 'Source Sans Pro';
  margin: 0 auto;

  & > div {
    cursor: pointer;
    position: relative;
  }

  @media (max-width: 1199px) {
    gap: 20px;
    font-size: 18px;
    flex-direction: column;
  }

  @media (min-width: 1200px) {
    gap: 40px;
    font-size: 28px;
  }
}

.line {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #000;
  animation: apparitionLigne 500ms ease-in-out;
}

@keyframes apparitionLigne {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}
