.headerContainer {
  min-height: 150px;
  min-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 13%;
  gap: 25%;

  @media (max-width: 1199px) {
    gap: 20px;
  }
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  :hover {
    cursor: pointer;
  }
}

.h1 {
  margin: 0px;
  font-family: 'Source Sans Pro';
  font-weight: 700;
  font-size: 48px;

  @media (max-width: 1199px) {
    font-size: 26px;
  }
}
.h2 {
  margin: 0px;
  font-family: 'Source Sans Pro';
  font-weight: 300;
  font-size: 32px;

  @media (max-width: 1199px) {
    font-size: 18px;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.icons {
  margin: 20px;

  @media (max-width: 1199px) {
    margin: 10px;
  }
}
