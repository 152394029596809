.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  backdrop-filter: blur(10px);
  border: 3px solid white; 
  border-radius: 15px; 
  padding: 40px;
  gap: 20px;
  max-width: 40%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); 
  animation: apparition 500ms ease-in-out;

  @media (max-width: 799px) {
    gap: 5px;
  padding: 20px;
  max-width: 60%;
}
}

.formSection {
  width: 90%;
  display: flex;
  flex-direction: column;
  font-family: 'Source Sans Pro';

  @media (max-width: 799px) {
    margin: 10px 0;
    gap: 5px;
  }
  
  & > input {
    background-color: white;
    border-radius: 15px; 
    border: none;
    width: 100%;
    height: 40px;
    text-align: center;

    @media (max-width: 799px) {
      height: 30px;
    }
  }
  
  & > textarea {
    border: none;
    background-color: white;
    border-radius: 15px; 
    width: 100%;
    min-height: 100px;

    @media (max-width: 799px) {
      min-height: 150px;
    }
  }
  
  & > input::placeholder,
  & > textarea::placeholder {
    text-align: center;
    font-size: 16px; 
    font-style: italic; 

    @media (max-width: 799px) {
      font-size: 12px;
    }
  }

  & > input:focus,
  & > textarea:focus {
    outline: none;
  }
}

.button {
  background: none;
  border: none;
  outline: none;
  outline: 4px solid var(--background-medium);
  padding: 10px 20px;

  border-radius: 15px; 
  background-color: white;
  color:grey;
  cursor: pointer;

  &:disabled {
    background-color: darkgray;
    color:grey;
    cursor: not-allowed;
  }
  
  &:not(:disabled):hover {
    background-color: grey;
    color: white;
    outline: 4px solid white;
  }

  @media (max-width: 799px) {
  }
}

@keyframes apparition {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
