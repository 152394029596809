.icons {
  display: flex;
  margin: 1%;
  :hover {
    cursor: pointer;
  }
}

.iconSize {
  min-height: 48px;
  max-height: 48px;
  min-width: 48px;
  max-width: 48px;

  @media (max-width: 1199px) {
    min-height: 24px;
    max-height: 24px;
    min-width: 24px;
    max-width: 24px;
  }
}

.verticalIconSize {
  min-height: 26px;
  max-height: 26px;
  min-width: 26px;
  max-width: 26px;

  @media (max-width: 1199px) {
    min-height: 16px;
    max-height: 16px;
    min-width: 16px;
    max-width: 16px;
  }
}
