.formationContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: 'Source Sans Pro';
  animation: apparition 500ms ease-in-out;
}

@keyframes apparition {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.formation {
  margin: 2% 7.5%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1199px) {
    gap: 5px;
    justify-content: center;
  }
}

.h3 {
  font-family: 'Source Sans Pro';
  font-size: 38px;
  font-weight: 400;
  align-self: flex-start;
  margin: 0;
  position: relative;

  @media (max-width: 1199px) {
    font-size: 24px;
  }
}

.formationDetail {
  font-size: 30px;
  display: flex;
  gap: 20px;
  align-self: flex-start;

  @media (max-width: 1199px) {
    font-size: 18px;
    gap: 5px;
  }
}

.formationSchool {
  font-weight: 400;

  @media (max-width: 1199px) {
    max-width: 60%;
  }
}

.formationPlace {
  font-weight: 200;
}

.formationDates {
  font-size: 25px;
  font-weight: 200;
  align-self: flex-start;
  font-style: italic;

  @media (max-width: 1199px) {
    font-size: 15px;
  }
}

.formationSpeciality {
  font-size: 22px;
  font-weight: 400;
  align-self: flex-start;

  @media (max-width: 1199px) {
    font-size: 15px;
  }
}

.line {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #000;
}
